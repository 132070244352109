<template>
  <menu-tab :menu="menu" @change="selectMenu" :initIndex="1"></menu-tab>

  <div class="list-container">
    <list url="/M/News/GetMoreNews/0" :show="currentMenuItem == '全部'">
      <template v-slot:default="data">
        <news-item :newData="data"></news-item>
      </template>
    </list>
    <list url="/M/News/GetMoreNews/1" :show="currentMenuItem == '北纳动态'">
      <template v-slot:default="data">
        <news-item :newData="data"></news-item>
      </template>
    </list>
    <list url="/M/News/GetMoreNews/2" :show="currentMenuItem == '行业新闻'">
      <template v-slot:default="data">
        <news-item :newData="data"></news-item>
      </template>
    </list>
    <list url="/M/News/GetMoreNews/3" :show="currentMenuItem == '技术前沿'">
      <template v-slot:default="data">
        <news-item :newData="data"></news-item>
      </template>
    </list>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, onUnmounted } from "vue";
  // components
  import list from "@/components/common/list.vue";
  import newsItem from "@/components/common/NewsItem.vue";
  import MenuTab from "@/components/common/MenuTab.vue";

  export default defineComponent({
    name: "News",
    components: {
      list,
      newsItem,
      MenuTab
    },
    setup() {
      //  切换列表菜单
      type Menu = "全部" | "北纳动态" | "行业新闻" | "技术前沿";
      const menu: Menu[] = ["全部", "北纳动态", "行业新闻", "技术前沿"];
      const currentMenuItem = ref(menu[1]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        menu,
        currentMenuItem,
        selectMenu,
      };
    },
  });
</script>

<style lang="scss" scoped>
  //  list  nav
  .menu-nav-wapper {
    overflow: hidden;
    height: 45px;
    width: 375px;
    line-height: 45px;
    position: fixed;
    top: 55px;
    z-index: 100;
    background-color: #fff;
    border-bottom: 1.012px solid #f4f4f4;
    .menu-nav {
      overflow: hidden;
      height: 45px;
      width: 100%;
      line-height: 45px;
      border-bottom: 1px solid #f4f4f4;
      top: 0;
      z-index: 100;
      background-color: #fff;
    }
    li {
      float: left;
      width: 25%;
      text-align: center;
      a {
        display: block;
        text-align: center;
        line-height: 45px;
        width: 100%;
        font-size: 16px;
        color: #666;
      }
    }

    .active a {
      color: #df0024;
    }
  }
  .list-container {
    padding-top: 45px;
  }
</style>
