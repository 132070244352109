
  import { defineComponent, ref, onMounted, onUnmounted } from "vue";
  // components
  import list from "@/components/common/list.vue";
  import newsItem from "@/components/common/NewsItem.vue";
  import MenuTab from "@/components/common/MenuTab.vue";

  export default defineComponent({
    name: "News",
    components: {
      list,
      newsItem,
      MenuTab
    },
    setup() {
      //  切换列表菜单
      type Menu = "全部" | "北纳动态" | "行业新闻" | "技术前沿";
      const menu: Menu[] = ["全部", "北纳动态", "行业新闻", "技术前沿"];
      const currentMenuItem = ref(menu[1]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        menu,
        currentMenuItem,
        selectMenu,
      };
    },
  });
